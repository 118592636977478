import React from "react";
import { Link, graphql } from 'gatsby';
import Img from "gatsby-image";
import Layout from "../layouts/layout";
import Testimony from "/src/components/testimony";

const Careers = ({ data: { hero, pageData, professionals_thumbnail, skilled_craft_thumbnail, students_thumbnail, recent_grads_thumbnail } }) => {

hero.buttons = (
    <div className="mt-8">
        <Link target="_blank" to="https://mena.manenterprise.com/Man/application/hrms/online/index.php/safe_Login_cont/index/man/mcor/1/1">
            <button className="bg-transparent hover:bg-mane-yellow text-white text-xs font-semibold font-aktivcd hover:text-gray-800 uppercase py-3 px-8 border border-white hover:border-transparent tracking-tight mr-6">
                find your role
            </button>
        </Link>
        {/*
        <button className="bg-transparent hover:bg-mane-yellow text-white text-xs font-semibold font-aktivcd hover:text-gray-800 uppercase py-3 px-8 border border-white hover:border-transparent tracking-tight">
            <svg className="w-4 h-4 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
            search jobs
        </button>
        */}
    </div>
)

return (

<Layout hero={hero}>
    <div className="container mx-auto mb-16">

    {/* Our Values */}
    <div className="commitment-anchor" id="why-man-group" />
        <h2 className="promo-heading px-4 mb-8">Why MAN Enterprise Group?</h2>

    <div className="md:flex items-center justify-between mb-16">

        <div className="flex-1 px-4 mb-4">
                <div className="soft-gray leading-relaxed nested-html"
                    dangerouslySetInnerHTML={{ __html: pageData.promoThreeText.childMarkdownRemark.html }}
                />                    
            </div>

               
    </div>


    <div className="commitment-anchor" id="our-resources" />
    <div className="md:flex items-center justify-between mb-16">
        <div className="flex-1 px-4 mb-4 order-last">
        <h2 className="promo-heading mb-8">Our Resources</h2>
            <div className="soft-gray leading-relaxed nested-html"
                dangerouslySetInnerHTML={{ __html: pageData.promoOneText.childMarkdownRemark.html }}
            />                    

            <div className="sm:flex mt-12">
                <div className="flex-1 text-center">
                    <h5 className="text-2-5 font-aktiv dark-blue font-bold">8</h5>
                    <p className="soft-gray">cities with MAN facilities</p>
                </div>
                <div className="flex-1 text-center">
                    <h5 className="text-2-5 font-aktiv dark-blue font-bold">15</h5>
                    <p className="soft-gray">nationalities currently employed</p>
                </div>
                <div className="flex-1 text-center">
                    <h5 className="text-2-5 font-aktiv dark-blue font-bold">8,000</h5>
                    <p className="soft-gray">labors under daily management</p>
                </div>
            </div>
        </div>

        <div className="flex-1 px-4 text-right sm:mb-0 self-start">
            <Img className="object-cover object-center w-full h-full block" fluid={pageData.promoImage.fluid} />
        </div>
               
    </div>

    <div className="commitment-anchor" id="working-at-man-enterprise" />
    <div className="md:flex items-center justify-between mb-16">

        <div className="flex-1 px-4 mb-4">
        <h2 className="promo-heading mb-8">Working at MAN Enterprise</h2>
            <div className="soft-gray leading-relaxed nested-html"
                dangerouslySetInnerHTML={{ __html: pageData.promoTwoText.childMarkdownRemark.html }}
            />                    
        </div>

        <div className="flex-1 px-4 text-right sm:mb-0 self-start">
            <Img className="object-cover object-center w-full h-full block" fluid={pageData.promoTwoBackgroundImage.fluid} />
        </div>


    </div>

</div>

    {/* Testimonies */}

    <div className="border-t border-b" style={{backgroundColor: "#f5f5f5"}}>
        {pageData.testimonies.map((testimony, i) => <Testimony testimony={testimony} count={i} /> )}
    </div>

    <div className="container mx-auto mb-16 px-4">

                <div className="mt-4 md:flex flex-wrap justify-between">

                    <div className="border-shadow cards-four flex flex-col mt-4">
                                <Link to="/careers/professionals">
                                    <Img className="object-cover object-center w-full h-full block" fluid={professionals_thumbnail.fluid} />
                                </Link>
                            <div className="mt-2 p-4">
                                <h3 className="text-gray-800 text-xl font-bold mb-2">Professionals</h3>
                                <p className="text-sm soft-gray">Whatever your background is, we have the size, scale, training, and culture.</p>
                            </div>
                            <div className="p-4 mb-6 mt-auto">
                                <div className="inline-block uppercase border-b-2 text-xs border-mane-yellow text-gray-800 font-bold">
                                    <Link to="/careers/professionals">Learn More</Link>
                                </div>
                            </div>
                    </div>

                    <div className="border-shadow cards-four flex flex-col mt-4">
                                <Link to="/careers/students-recent-grads">
                                    <Img className="object-cover object-center w-full h-full block" fluid={recent_grads_thumbnail.fluid} />
                                </Link>
                            <div className="mt-2 p-4">
                                <h3 className="text-gray-800 text-xl font-bold mb-2">Recent Grads</h3>
                                <p className="text-sm soft-gray">When you join MAN Enterprise Group, you join a global Family!</p>
                            </div>
                            <div className="p-4 mb-6 mt-auto">
                                <div className="inline-block uppercase border-b-2 text-xs border-mane-yellow text-gray-800 font-bold">
                                    <Link to="/careers/students-recent-grads">Learn More</Link>
                                </div>
                            </div>
                    </div>

                   <div className="border-shadow cards-four flex flex-col mt-4">
                                <Link to="/careers/skilled-craft">
                                    <Img className="object-cover object-center w-full h-full block" fluid={skilled_craft_thumbnail.fluid} />
                                </Link>
                            <div className="mt-2 p-4">
                                <h3 className="text-gray-800 text-xl font-bold mb-2">Skilled Craft</h3>
                                <p className="text-sm soft-gray">Whether it is construction, maintenance or manufacturing; skilled crafts are the foundation of our campus.</p>
                            </div>
                            <div className="p-4 mb-6 mt-auto">
                                <div className="inline-block uppercase border-b-2 text-xs border-mane-yellow text-gray-800 font-bold">
                                    <Link to="/careers/skilled-craft">Learn More</Link>
                                </div>
                            </div>
                    </div>

                    <div className="border-shadow cards-four flex flex-col mt-4">
                                <Link to="/careers/students-recent-grads">
                                    <Img className="object-cover object-center w-full h-full block" fluid={students_thumbnail.fluid} />
                                </Link>
                            <div className="mt-2 p-4">
                                <h3 className="text-gray-800 text-xl font-bold mb-2">Students</h3>
                                <p className="text-sm soft-gray">Join our summer internship program LIFE at MAN Enterprise (Leading Internship, Field Experience) and meet the best mentors of the industry.</p>
                            </div>
                            <div className="p-4 mb-6 mt-auto">
                                <div className="inline-block uppercase border-b-2 text-xs border-mane-yellow text-gray-800 font-bold">
                                    <Link to="/careers/students-recent-grads">Learn More</Link>
                                </div>
                            </div>
                    </div>


                </div>
            </div>



</Layout>

)};

export const query = graphql`
    query CareersLanding {
      hero: contentfulCareers(slug: {eq: "careers"}) {
        heroBackgroundImage {
          resize(width: 1440) {
           src
          }
        }
        heroHeading
      }

      pageData: contentfulCareers(slug: {eq: "careers"}) {
        promoOneText {
           childMarkdownRemark {
                   html
             }
        }
        promoImage { 
            fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
        }
        promoTwoBackgroundImage {
            fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
        }
        promoTwoText {
          childMarkdownRemark {
            html
          }
       }  
       promoThreeText {
        childMarkdownRemark {
            html
        }
       }
       testimonies {
         name
         position
         testimony {
          testimony
        }
         picture {
            fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
         }
                                                                                  }
      }
      professionals_thumbnail: contentfulAsset(contentful_id: {eq: "61RN2TwJSIi1VffZiGDYLd"}) {
        fluid  {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
      }
      skilled_craft_thumbnail: contentfulAsset(contentful_id: {eq: "3RNPzvmF2DuKLSFux4nYaM"}) {
        fluid  {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
      }
      students_thumbnail: contentfulAsset(contentful_id: {eq: "4JIjj9RLJCNyfVcqkt2mGF"}) {
            fluid  {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
          }
      recent_grads_thumbnail: contentfulAsset(contentful_id: {eq: "1bOEzvEDQRJeUObAirTzJN"}) {
            fluid  {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
          }

    }
`;


export default Careers;
